import { Component } from '@angular/core';
import { SharedProviderService } from './shared/services/shared-provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public sharedProviderService: SharedProviderService
  ) { }
}
