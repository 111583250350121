import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    showChangePasswordDialog: boolean = false;

    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.model = [
            {
                label: 'Acasă',
                items: [
                    { 
                        label: 'Pagina principală', 
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/dashboard']
                    }
                ]
            },
            {
                label: 'Cont',
                items: [
                    // { 
                    //     label: 'Setări cont', 
                    //     icon: 'pi pi-fw pi-id-card',
                    //     routerLink: ['settings']
                    // },
                    // { 
                    //     label: 'Schimbă parola', 
                    //     icon: 'pi pi-fw pi-lock',
                    //     routerLink: ['change-password']
                    // },
                    { 
                        label: 'Deconectare', 
                        icon: 'pi pi-fw pi-sign-out',
                        command: () => this.authService.logout()
                    }
                ]
            }
        ];

        if (localStorage.getItem('change_pass') == 'true') {
            this.showChangePasswordDialog = true;
        }
    }

    onCloseChangePassword(event: any) {
        if (event) {
            this.showChangePasswordError(event.error);
        } else {
            this.showChangePasswordSuccess();
            this.showChangePasswordDialog = false;

            localStorage.setItem('change_pass', 'false');
        }
    }

    showChangePasswordError(err: any) {
        this.messageService.add({ severity:'error', summary: 'Eroare', detail: err.message });
    }

    showChangePasswordSuccess() {
        this.messageService.add({ severity:'success', summary: 'Success', detail: "Parola a fost modificată cu succes!" });
    }
}
