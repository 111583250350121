import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ToastModule } from 'primeng/toast';
import { APP_PRIMENG_MODULE } from './shared/APP.MODULE-PRIMENG';
import { AppLayoutModule } from './modules/layout/app.layout.module';
import { BrandService } from './shared/services/brand.service';
import { Observable } from 'rxjs';
import { TermsAndConditionsComponent } from "./shared/components/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { SharedProviderService } from './shared/services/shared-provider.service';

function initBrandSettings(http: HttpClient, brandService: BrandService): () => Observable<any> {
  return () => brandService.getBrandSettings();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    APP_PRIMENG_MODULE,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent
],
  providers: [
    AuthService,
    AuthGuard,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MessageService,
    { provide: LOCALE_ID, useValue: 'ro-RO'},
    {
      provide: APP_INITIALIZER,
      useFactory: initBrandSettings,
      deps: [HttpClient, BrandService],
      multi: true
    },
    SharedProviderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
