import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedProviderService {
  showTermsAndConditions: boolean = false;
  showPrivacyPolicy: boolean = false;

  constructor() { }
}
