<router-outlet></router-outlet>

<p-toast position="bottom-center"></p-toast>

<p-dialog [(visible)]="sharedProviderService.showTermsAndConditions" styleClass="w-full h-full" [modal]="true" [dismissableMask]="true">
    <app-terms-and-conditions></app-terms-and-conditions>
</p-dialog>

<p-dialog [(visible)]="sharedProviderService.showPrivacyPolicy" styleClass="w-full h-full" [modal]="true" [dismissableMask]="true">
    <app-privacy-policy></app-privacy-policy>
</p-dialog>