<h4>POLITICA DE CONFIDENȚIALITATE</h4>

<h6 class="text-red-700">{{brandService.brandSettings.name || ''}} este o platformă generată folosind soft-ul FIDEVO</h6>

<h5>Politica de protectie a datelor personale</h5>
<p>
    FIDEVO incearca sa protejeze cat mai mult posibil dreptul privind confidentialitatea si imaginea utilizatorilor site-ului. Intentia noastra este aceea de a iti oferi tie, utilizatorul site-ului, o experienta online sigura, care nu iti va afecta negativ viata personala. In acest sens, depunem toate eforturile necesare pentru a ne asigura ca informatiile pe care le introduci in baza noastra de date sunt utilizate exclusiv in scopurile gandite de tine.
</p>

<h5>Datele personale pe care le prelucram</h5>
<p>
    Prin accesarea acestuia sau prin activarea mesajelor promotionale sau publicitare, FIDEVO va prelucra informatiile cu caracter personal de natura sa identifice o anumita persoana. Datele cu caracter personal pe care le prelucram pot include datele personale furnizate de tine
</p>

<ul>
    Cand te abonezi / folosesti un serviciu de pe site-ul nostru sau prin intermediul metodelor directe de contact iti vom cere sa ne furnizezi informatii cu caracter personal, inclusiv numele si prenumele, numarul de telefon, adresa de email, varsta, detaliile pentru facturare dupa caz / activitati promotionale. 
</ul>
<ul>
    Cand te abonezi la Newsletter-ul FIDEVO, iti vom cere sa ne furnizezi adresa ta de email.
</ul>
<ul>
    Cand ne scrii, folosind formularele de pe site-ul nostru, iti cerem sa ne furnizezi datele tale de contact: numele si prenumele, adresa de email, numarul de telefon.
</ul>


<h5>Colectarea si utilizarea datelor cu caracter personal de catre FIDEVO</h5>

<p>
    fidevo.ro colecteaza informatii de la utilizatorii sai in trei modalitati – direct de la utilizator, din rapoartele traficului inregistrat de serverele care gazduiesc siteurile noastre si prin intermediul cookieurilor.
</p>
<p>
    Actiunea de creeare cont pe platforma fidevo.ro, justifica faptul ca utilizatorul este de acord cu politica FIDEVO de colectare si manipulare a datelor, in acord cu regulamentul GPDR. 
</p>
<p>
    FIDEVO utilizeaza informatiile tale cu caracter personal stocate - detaliile furnizate la inregistrare si activitatea pe care ai desfasurat-o pe site-ul nostru - in special pentru a iti usura accesul pe site, pentru a iti oferi experienta completa a serviiilor noastre, pentru a primi oferte relevante potrivite profilului tau, pentru a imbunatati produsele si serviciile si pentru a te informa cu privire la ultimele produse si servicii de care esti interesat.
</p>
<p>
    Este posibil sa folosim datele tale cu caracter personal exclusiv pentru urmatoarele scopuri
</p>

<h6>A. Furnizarea serviciilor noastre</h6>
<p>In primul rand, folosim datele tale cu caracter personal pentru a iti furniza serviciile de access platforma FIDEVO si sub-serviciile oferite de aceasta</p>

<h6>B. Comunicarea cu tine</h6>
<p>Utilizam datele tale cu caracter personal pentru a te contacta in legatura cu serviciile pe care le-ai achizitionat de la noi. Este posibil sa te contactam prin email, posta, telefon sau prin mesaje text SMS.. Metoda depinde de informatiile de contact pe care ni le-ai impartasit anterior si de metoda prin care ne-ai contactat.</p>
<p>Motivele pentru care te-am putea contacta includ:</p>
<ul>informare in privinta activitatii desfasurate prin aplicatia FIDEVO;</ul>
<ul>confirmarea, din partea ta, a detaliilor contului tau.;</ul>
<ul>raspunsul la sau gestionarea oricaror cereri din partea ta;</ul>
<ul>trimiterea, catre tine, a unui chestionar sau invitatia de a ne oferi o recenzie a experientei avute cu noi, in urma achizitionarii serviciilor noastre;</ul>
<ul>trimiterea, catre tine, a altor informatii sau materiale administrative, legate de serviciile pe care le-ai achizitionat, cum ar fi alertele de securitate, cum sa ne contactezi in cazul in care ai nevoie de asistenta.</ul>
<ul>primirea de oferte personalizate, sub forma de notificare / mesaj text SMS</ul>
<p>Cand ne contactezi (prin telefon, email, Facebook sau prin intermediul oricarei alte retele de socializare, WhatsApp etc.), agentii FIDEVO  iti vor solicita cateva detalii, pentru a te identifica in mod corespunzator. Iti vom adresa intrebari cu privire la detaliile pe care ni le-ai furnizat in cadrul crearii contului, astfel incat, daca nu vei putea sa ne furnizezi suficiente detalii, este posibil sa nu putem ajuta la solutionarea cererii tale.</p>

<h6>C. Activitati de marketing</h6>
<p>Utilizam datele cu caracter personal furnizate de tine pentru activitati de marketing, care pot include:</p>
<ul>utilizarea adresei tale de email, pentru a iti trimite buletinul informativ (Newsletter) cu privire la produsele si serviciile noastre. Poti sa te dezabonezi de la comunicarile de marketing prin email, in orice moment, facand click pe link-ul Unsubscribe, inclus la finalul fiecarui email;</ul>
<ul>pe baza informatiilor privitoare la cautarile si achizitiile tale anterioare, putem include in Newsletter-urile noastre oferte personalizate, pentru a iti permite sa alegi dintre cele mai bune oferte care te-ar putea interesa;</ul>
<ul>daca participi la alte activitati promotionale pe care le desfasuram, vor fi utilizate informatii relevante pentru administrarea acestor activitati;</ul>
<ul>din cand in cand, este posibil sa iti cerem sa participi la cercetari de marketing. Cand esti invitat sa participi la astfel de cercetari, vei primi toate informatiile necesare pentru a intelege care sunt datele cu caracter personal colectate si cum datele tale cu caracter personal sunt utilizate in continuare.</ul>
<p>Notificari despre oferte personalizarte, trimise prin cadrul sistemului SMS - text, pe dispozitivul mobil.</p>
<p>Este necesar sa ai cel putin 16 ani pentru a te abona la comunicarile noastre de marketing prin email.</p>

<h6>D. Imbunatatirea calitatii serviciilor noastre</h6>
<p>Ne straduim in mod constant sa ne imbunatatim serviciile si este posibil sa folosim datele cu caracter personal in scopuri analitice, pentru a optimiza si personaliza platforma noastra online, conform nevoilor tale, transformand site-ul nostru intr-unul mai usor de utilizat si mai agreabil si sa ne imbunatatim Serviciul de relatii cu clientii.</p>

<h6>E. Scopuri juridice</h6>
<p>Este posibil sa utilizam datele tale pentru a ne respecta obligatiile legale (cum ar fi, de exemplu, obligatiile privind pastrarea inregistrarilor), pentru verificarea conformitatii sau obligatiile de inregistrare (de exemplu, conform legilor antimonopol, a legilor privind exportul, sanctiuni comerciale si legile privind embargo-ul, legile contra spalarii banilor, verificarea financiara si a creditelor si prevenirea fraudelor si a infractiunilor si in scopul depistarii acestora).</p>
<p>De asemenea, este posibil sa iti utilizam datele, daca este necesar, cu scopul de a respecta ordinele instantelor de judecata si de a ne urmari si a ne apara drepturile legale.</p>

<h5>Temeiurile legale pentru prelucrarea datelor tale personale</h5>
<p>Luand in considerare faptul ca ai dreptul sa cunosti scopul prelucrarii datelor tale cu caracter personal, te vom informa anterior prelucrarii datelor tale cu caracter personal in alte scopuri decat cele pentru care ne-ai divulgat datele tale cu caracter personal.</p>

<h6>Executarea contractelor cu clientii nostri</h6>
<p>Pe baza contractului sau a termenilor si conditiilor acceptate de tine, in calitate de client al FIDEVO, iti vom prelucra datele cu caracter personal pe care ni le-ai pus la dispozitie, precum si orice alte date necesare pentru ca noi sa iti putem furniza serviciile noastre, conform prevederilor contractuale si celor ale legii din Romania</p>
<p>Executarea contractului incheiat cu tine este temeiul legal pentru prelucrarea datelor tale cu caracter personal in urmatoarele scopuri: furnizarea serviciilor noastre, comunicarea cu tine si activitatile legate de serviciul de relatii cu clientii.</p>

<h6>Interesele legitime</h6>
<p>Este posibil ca noi sa folosim informatiile tale pentru interese legitime, cum ar fi furnizarea serviciilor noastre, in cazul in care serviciul este achizitionat de catre o alta persoana decat beneficiarul.</p>
<p>Este posibil sa iti folosim informatiile pentru interesele noastre legitime, pentru a iti putea oferi cel mai bun si adecvat continut al site-ul web, al email-urilor si buletinelor informative, pentru a imbunatati si promova produsele si serviciile noastre si continutul site-ului nostru web si pentru monitorizarea apelurilor, in scopuri administrative, inclusiv pentru securitatea sediului nostru si in scopuri juridice.</p>
<p>Folosim datele tale cu caracter personal pe baza consimtamantului tau specific pentru a te abona la buletinul nostru informativ privind produsele si serviciile noastre. Poti sa iti retragi consimtamantul facand click pe butonul unsubscribe la finalul fiecarui email.</p>

<h6>Obligatiile legale</h6>
<p>Este posibil sa prelucram unele dintre datele tale, in contextul furnizarii serviciilor, pe baza obligatiilor legale (de ex., in scopuri de raportare sau in scopuri de combatere a spalarii banilor) sau a obligatiilor impuse site-ului www.fidevo.ro de legile aplicabile.</p>

<h6>Distribuirea si divulgarea informatiilor cu caracter personal</h6>
<p>Ca regula, FIDEVO nu va furniza (prin vanzare sau inchiriere) catre terti informatiile tale cu caracter personal. Cu toate acestea, FIDEVO va transmite informatiile catre terti, cand acest lucru este necesar in scopul prelucrarii datelor, cum ar fi urmatoarele cazuri:</p>
<p><strong>Partenerii de afaceri</strong></p>
<p>Este posibil sa lucram cu parteneri de afaceri, care ofera servicii comercializate pe site-ul nostru (, asigurare, furnizori de plati sau alte intreprinderi in domeniul HoReCa). In momentul in care achizitionezi un serviciu de la noi, anumite date cu caracter personal necesare pentru furnizarea unor astfel de servicii, cum ar fi numele si adresa ta de email, adresa de domiciliu, detaliile de plata si alte informatii relevante, pot fi transmise partenerilor nostri de afaceri. Datele transmise partenerilor nostri de afaceri sunt limitate la datele cu caracter personal necesare pentru furnizarea serviciului, conform celor stabilite de partenerii de afaceri.</p>
<p><strong>Autoritatile competente</strong></p>
<p>Este posibil sa divulgam date cu caracter personal institutiilor care aplica legea, in masura in care acest lucru este solicitat prin lege sau este strict necesar pentru prevenirea, depistarea sau urmarirea penala a infractiunilor si a fraudelor sau daca suntem altfel obligati, prin lege, sa facem acest lucru. Este posibil sa fim nevoiti sa divulgam date cu caracter personal catre Autoritatile competente pentru a ne proteja si a ne apara drepturile sau bunurile sau drepturile si bunurile partenerilor nostri de afaceri.</p>
<p><strong>Alte situatii</strong></p>
<p>In anumite situatii specifice, cand colectam datele tale cu caracter personal, este posibil sa iti cerem consimtamantul pentru transmiterea respectivelor date catre terti. In acest caz, te vom informa cu privire la o astfel de prelucrare si nu vom efectua transmiterea datelor inainte de a obtine consimtamantul tau.</p>

<h6>Persoanele imputernicite cu prelucrarea datelor personale</h6>
<p>Pentru prelucrarea datelor cu caracter personal, putem utiliza persoane imputernicite pentru prelucrarea datelor personale, precum furnizori de solutii IT necesare pentru managementul afacerii, inclusiv solutii de contabilitate, furnizori de solutii de management contractual sau organizatori de conferinte. Vom incheia acorduri pentru prelucrarea datelor cu caracter personal cu toate persoanele imputernicite cu prelucrarea datelor personale, care vor include clauze corespunzatoare, care sa ne asigure ca acestea isi asuma obligatiile de a prelucra datele cu caracter personal (inclusiv obligatia de a le sterge) in acord deplin cu legile aplicabile si ca ofera o protectie adecvata datelor tale cu caracter personal.</p>

<h5>Durata stocarii</h5>
<p>Am implementat masuri tehnice si organizatorice, pentru a organiza procesul si criteriile prezentate pentru stergerea datelor tale cu caracter personal.</p>
<p>Datele tale cu caracter personal vor fi sterse in momentul in care nu mai sunt necesare, in mod rezonabil, scopurilor autorizate sau atunci cand iti retragi consimtamantul (acolo unde este cazul) si nu mai este necesar, din punct de vedere legal, ca noi sa mai stocam in continuare aceste date. Cu toate acestea, vom retine datele tale cu caracter personal, acolo unde este necesar ca noi sa sustinem sau sa te aparam pe tine sau alte persoane impotriva oricaror revendicari legale, pana la sfarsitul perioadei relevante de pastrare sau pana cand revendicarile in cauza au fost solutionate.</p>

<h5>Securitatea datelor cu caracter personal</h5>
<p>Datele cu caracter personal sunt securizate impotriva amenintarilor si noi ne asiguram ca acestea sunt protejate de o infrastructura IT si masuri de securitate corespunzatoare. Daca descoperim orice incalcare a datelor cu caracter personal, care prezinta un risc pentru drepturile si libertatile tale, vom informa Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal. Vei fi informat cu privire la incalcarea datelor tale cu caracter personal, daca acest lucru duce la un risc ridicat pentru drepturile si libertatile tale.</p>

<h5>Drepturile tale cu privire la prelucrarea datelor cu caracter personal</h5>
<p>Ai urmatoarele drepturi pentru prelucrarea datelor cu caracter personal:</p>
<ul>Dreptul de a iti accesa propriile date cu caracter personal.</ul>
<ul>Dreptul de a iti corecta propriile date cu caracter personal inexacte.</ul>
<ul>
    Dreptul de a restrictiona prelucrarea propriilor date cu caracter personal. Acest drept este disponibil pentru tine atunci cand:
    <ul>    
        contesti exactitatea propriilor date cu caracter personal pe care le prelucram;
    </ul>
    <ul>    prelucrarea datelor tale cu caracter personal este nelegala;
    </ul>
    <ul>    nu mai avem nevoie de datele tale cu caracter personal, in scopul prelucrarii, insa acestea iti sunt necesare pentru constatarea, exercitarea sau apararea unui drept in instanta;
    </ul>
    <ul>    te-ai opus cu privire la prelucrarea datelor tale cu caracter personal, pentru intervalul de timp in care se verifica daca drepturile legitime ale operatorului prevaleaza asupra drepturilor tale.
    </ul>
</ul>

<p>In cazul in care ai intrebari cu privire la prelucrarea datelor tale cu caracter personal sau daca doresti sa ne trimiti o cerere sau sa iti exerciti oricare dintre drepturile legate de prelucrarea datelor cu caracter personal, te rugam sa ne contactezi la .</p>
<p>Vom analiza fiecare cerere si te vom informa cu privire la actiunile intreprinse, in cel mai scurt timp posibil, in termen de maximum o luna de zile. In cazul in care vom avea nevoie de mai multe informatii de la tine sau daca intampinam dificultati in solutionarea cererii tale, te vom informa fara intarziere cu privire la faptul ca avem nevoie de mai mult timp pentru a efectua o analiza corespunzatoare a cererii tale.</p>
<p>In cazul in care vei considera ca nu am reusit sa iti solutionam toate cererile sau daca nu esti multumit de raspunsurile noastre, poti depune o petitie la Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal. Poti, de asemenea, sa apelezi la instantele de judecata.</p>

<h5>Despre noi</h5>
<p>Operatorul responsabil cu prelucrarea datelor tale cu caracter personal, conform descrierii din prezenta Politica de confidentialitate, este FIDEVO, o societate romaneasca, avand sediul social in </p>

<h5>Amendamente la Politica de protectie a datelor personale</h5>
<p>In cazul in care hotaram modificarea prezentei notificari, pentru a furniza o mai buna protectie a drepturilor si datelor tale cu caracter personal, vom publica noua versiune aici, iar aceasta va inlocui versiunea actuala.</p>

<p>Iti multumim pentru increderea pe care ne-o acorzi cu privire la datele tale cu caracter personal si pentru ca ai alocat o parte din timpul tau citirii notificarii noastre cu privire la prelucrarea datelor cu caracter personal. Te rugam, nu ezita sa ne contactezi daca ai intrebari legate de datele tale cu caracter personal si prelucrarea efectuata de noi a acestor date</p>
