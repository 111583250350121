import { Component } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { BrandService } from 'src/app/shared/services/brand.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
    constructor(
        public layoutService: LayoutService,
        public brandService: BrandService
    ) { }
}
