<h4>TERMENI ŞI CONDIȚII</h4>

<h6 class="text-red-700">{{brandService.brandSettings.name || ''}} este o platformă generată folosind soft-ul FIDEVO</h6>

<h5>1. Informatii despre FIDEVO</h5>

<p>
    Site-ul web <a href="https://fidevo.ro/">fidevo.ro</a> este proprietatea firmei FIDEVO SRL, cu sediul in Strada Bujorilor nr. 7, Suceava, inregistrata la Registrul Comertului cu numarul J33/754/2022, C.I.F. 45989337 numit pe parcursul acestor termeni si conditii FIDEVO.
</p>

<p>
    Platforma FIDEVO reprezintă o platformă complexă care conține pagini web cu conținuturi multimedia și aplicații interactive, care poate fi utilizată în mediul online de mai multe categorii de utilizatori cu roluri diferite. SC FIDEVO SRL este dezvoltatorul și proprietarul platformei FIDEVO, incluzând conținuturile și funcționalitățile pe care le pot accesa utilizatorii.
</p>

<p>
    Pentru a putea utiliza această platformă, dvs. trebuie să cunoașteți, să acceptați și să respectați pe tot parcursul utilizării FIDEVO condițiile prezentate în:
</p>
<h5>2. Descrierea serviciilor</h5>
<p>FIDEVO se adreseaza firmelor cu domeniul de activitate in vanzarea cafelei, si vine in ajutorul acestora oferind solutii inteligente pentru retentia clientilor / atragerea de noi clienti prin diverse functionalitati oferite prin intermediul aplicatiei.</p>

<h5>3. Proprietatea intelectuala</h5>
<p>
    Puteti utiliza continutul FIDEVO pentru folosinta dvs. personala, cat si folosinta in cadrul firmei contractata. (dupa caz) Actiunile descrise mai jos nu sunt permise in lipsa obtinerii in prealabil a unei permisiuni scrise din partea FIDEVO:
</p>
    <ul>a. indepartarea insemnelor care identifica dreptul de autor al FIDEVO asupra continutului;</ul>
    <ul>b. modificarea, publicarea, transmiterea, retransmiterea in orice mod sau forma, precum si participarea la transferul, vanzarea, distributia unor materiale realizate prin reproducerea, modificarea sau afisarea continutului, in lipsa obtinerii in prealabil a unei permisiuni scrise din partea noastra;</ul>
    <ul>c. reproducerea sau stocarea continutului, precum si trimiterea acestui continut catre orice alt website, server sau orice alt mijloc de stocare a informatiei, daca scopul acestei activitati este unul comercial.</ul>
<p>
    Este interzisa orice utilizare a continutului FIDEVO  in alte scopuri decat cele permise expres de prezentul document sau de legislatia in vigoare. Cererile de utilizare a continutului in alte scopuri decat cele permise expres de prezentul document pot fi trimise la adresa de email _______
</p>


<h5>4. Suspendarea accesului</h5>
<p>
    FIDEVO  poate, fara orice alta notificare sau formalitate si fara ca acest lucru sa necesite explicarea atitudinii sale, sa suspende sau sa blocheze accesul dvs. la continutul siteului sau la o parte a acestui continut in consecinta incalcarii vreunui paragraf mentionat in prezentul document.
</p>


<h5>5. Schimbari ale site-ului</h5>
<p>
    FIDEVO isi rezerva dreptul de a suspenda, modifica, adauga sau sterge in orice moment portiuni ale continutului sau. De asemenea, FIDEVO isi rezerva dreptul de a restrictiona accesul utilizatorilor la o parte sau la intregul sau continut.
</p>

<h5>6. Inregistrare, parole si responsabilitati</h5>
<p>
    Accesul dvs. la serviciul de Newsletter sau unele facilitati din cadrul site-ului necesita inregistrarea dvs. cu un nume de utilizator si o parola. Va recomandam sa nu dezvaluiti nimanui aceasta parola. FIDEVO nu va cere niciodata parola conturilor dvs in mesaje prin posta electronica sau telefon.
</p>
<p>
    Din pacate, nici o transmisie de date prin intemediul internetului nu poate fi garantata a fi 100% sigura. In consecinta, in ciuda eforturilor noastre de a va proteja informatia personala, FIDEVO nu poate asigura sau garanta securitatea informatiilor transmise de dvs. catre noi, catre si de la serviciile noastre online. Va avertizam asadar ca orice informatie trimisa catre noi se va face pe propriul dvs. risc.
</p>

<h5>7. Informatii generale</h5>
<p>
    FIDEVO respecta viata dvs. privata si datele cu caracter personal pe care le impartasiti cu noi in momentul accesarii acestui site. Aceaste informatii au rolul de a va informa cu privire la ce tip de informatie identificabila personal este colectata de la dvs., cum si unde am putea folosi aceasta informatie, cum intelegem noi sa protejam informatia colectata de la dvs., cine are acces la informatia colectata de la dvs. si cum pot fi corectate in timp inadvertentele din informatia preluata.
</p>
<p>
    FIDEVO respecta legislatia romana in vigoare, in speta Legea nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, modificata si completata si Legea nr. 506/2004 privind prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor electronice.
</p>

<p>
    Scopul colectarii datelor (vedeti si documentul GPDR) este acela de a creea posibilitatea de a avea acces mai usor la contul dvs. pe site-ul FIDEVO sau informarea utilizatorilor cu privire la noile stiri aparute, participarea la concursuri ori participarea la campanii promotionale desfasurate de FIDEVO.
</p>
<p>
    Scopul accesului FIDEVO la datele stocate pe calculatorul dvs. (cookie-uri) este de personalizarea continutului afisat - de exemplu de păstrarea setarilor personalizate ale fiecarui utilizator, autentificarea pentru comentarii sau realizarea de profile de utilizatori (fara a fi identificat un utilizator sau dispozitiv informatic anume) in scop de marketing.
</p>

<p>
    Scopul accesului tertelor rețele de publicitate la datele stocate pe calculatorul dvs. (cookie-uri) este de marketing prin Internet - de exemplu pentru afisarea unor reclame relevante pentru utilizatori si realizarea de profile de utilizatori (fara a fi identificat un utilizator sau dispozitiv informatic anume) in scop de marketing.
</p>
<p>
    Alte terte parti, cum ar fi cele care colecteaza date de trafic  sau cele apartinand retelele sociale (de ex. Facebook) integrate pe paginile FIDEVO pot utiliza cookie-uri pentru scopuri de colectare a informatiilor de trafic sau , respectiv, de a permite partajarea continutului cu reteaua sociala specifica.
</p>
<p>
    Utilizatorii care nu doresc accesul tertilor la datele stocate pe calculatorul dvs. (cookie-uri) pot folosi setarile browserului folosit pentru stergerea sau blocarea acestora. In ajutorul utilizatorilor nostri va indicam modul in care principalele trei browsere de pe piata permit acest lucru:
</p>

<h5>8. Ce informatii colectam si scopul acestora </h5>

<p>
    FIDEVO colecteaza informatii de la utilizatorii sai in trei modalitati – direct de la utilizator, din rapoartele traficului inregistrat de serverele care gazduiesc siteurile noastre si prin intermediul cookieurilor.
</p>
<p>
    Actiunea de creeare cont pe platforma FIDEVO si bifarea casutei specifica, justifica faptul ca utilizatorul este de acord cu politica FIDEVO de colectare si manipulare a datelor, in acord cu regulamentul GPDR. 
</p>
<p>
    Scopul pentru care FIDEVO SRL colecteaza datele utilizatorilor, este acela de a le oferi un serviciu personalizat / oferte specifice, targetate pe profilul utilizatorului.
</p>
<p>
    Informatie furnizata direct de utilizator: atunci cand va faceti un cont la FIDEVO, va putem intreba care este numele dvs., care este adresa dvs. de e-mail si va putem cere alte informatii personale. Datele pot fi corectate in sectiunea Profilul tau, accesibila pe parcusul intregii vizite in coltul din __________. Aceste infromatii trimise de dvs. nu vor fi partajate cu nici o terta parte.
</p>
<p>
    Informatie din raportul de trafic al serverului: atunci cand vizitati un site web, dezvaluiti anumite informatii despre dvs., ca adresa dvs. IP, ora vizitei dvs., locul de unde ati intrat in siteurile noastre. FIDEVO, inregistreaza aceaste informatii pentru o perioada de timp determinata. Utilizam servicii externe companiei de analiza a traficului – cum sunt cele puse la dispozitie de Trafic,ro, Google Analytics sau SATI – Studiu de Audienta si Trafic Internet. FIDEVO foloseste aceste date doar in scopul comunicarii sabloanelor tipizate
anonime ale utilizatorilor catre clientii nostri de publicitate. Nu utilizam datele de trafic pentru identificarea persoanelor din spatele unor adrese IP.
</p>

<h6>Cookie-uri: </h6>
<p>
    In ideea de a oferi un serviciu personalizat utilizatorilor nostri, FIDEVO poate folosi cookie-uri pentru a facilita accesul la contul dvs si la avantajele aduse de acesta. De asemenea, retele terte de publicitate online care isi desfasoara promotii pe site-ul nostru pot folosi cookieuri pentru a putea adapta mesajele publicitare in functie de preferintele dvs.
</p>
<p>
    Cookie-urile sunt fisiere de tip “.”, oferite browserului dvs. de un server web si stocate apoi pe hard-discul computerului dvs.. Folosirea cookieurilor este un standard actual la multe dintre siteurile importante pe care le vizitati.
</p>
<p>
    Majoritatea browserelor sunt setate sa accepte cookieuri. Daca insa nu preferati acest lucru, puteti reseta browserul dvs., fie sa va anunte ori de cate ori primiti cate un cookie, fie sa refuze chiar acceptarea cookieurilor. Cookieurile ne permit sa salvam parolele dvs. de acces si preferintele dvs. astfel incat sa nu fiti nevoit sa le introduceti din nou data viitoare cand ne veti vizita.
</p>

<h5>8. Cine are acces la informatiile colectate de la dvs.</h5>
<p>
    FIDEVO nu va dezvalui nici un fel de informatie identificabila personal despre utilizatorii sai catre terte parti fara a primi mai intai consimtamantul expres al utilizatorilor in aceasta privinta.
</p>
<p>
    In acelasi timp insa, FIDEVO poate dezvalui informatii identificabile personal atunci cand legea prevede expres acest lucru, cand este cerut de o autoritate competenta sau cand acest lucru este necesar pentru protejarea drepturilor si intereselor FIDEVO.
</p>
<p>
    In concluzie, atunci cand accesati siteurile FIDEVO si sunteti rugat sa ne dezvaluiti informatii despre dvs., veti dezvalui aceste informatii doar siteurilor FIDEVO, cu exceptia cazului cand serviciul sau informatia respectiva este oferita in parteneriat cu un alt site sau serviciu.
</p>


