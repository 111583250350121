import { Component } from '@angular/core';
import { BrandService } from '../../services/brand.service';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  constructor(
    public brandService: BrandService,
  ) { }
}
