<div class="layout-topbar">
    <a class="layout-topbar-logo font-semibold" routerLink="">
        {{brandService.brandSettings.name || ''}}
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <i class="pi pi-user"></i>
    </div>
</div>