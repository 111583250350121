import { Component } from '@angular/core';
import { BrandService } from '../../services/brand.service';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {

  constructor(
    public brandService: BrandService,
  ) { }

}
